*,
html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Abyssinica SIL", serif !important;
   
    
}
body{
    overflow-x: hidden;
    height: 100%;
    width: 100%;
}

.abyssinica-sil-regular {
    font-family: "Abyssinica SIL", serif;
    font-weight: 400;
    font-style: normal;
  }